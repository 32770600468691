'use strict';

export default {
  namespaced: true,
  state: {
    username: '',
    nombre: '',
    email: '',
    acronimo: '',
    modoapp: '',
    departamentos: [],
    permisos: [],
    secciones: [],
    tipologiasbusqueda: [],
    tipologiasefirma: [],

    autenticado: false,
    token: ''
  },
  actions: {
    setToken({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      if (!token) {
        state.autenticado = false;
      } else {
        state.autenticado = true;
      }
    },
    SET_USER(state, user) {
      //console.log(user)
      if (user) {
        state.username = user.username;
        state.compulsarennombrede = user.compulsarennombrede;
        state.acronimo = user.acronimo;
        state.nombre = user.nombre;
        state.email = user.email;
        state.departamentos = user.departamentos;
        state.permisos = user.permisos;
        state.secciones = user.secciones;
        state.tipologiasbusqueda = user.tipologiasbusqueda;
        state.tipologiasefirma = user.tipologiasefirma;
        state.modoapp = user.modoapp;
        state.autenticado = true;
      } else {
        state.username = '';
        state.compulsarennombrede = '';
        state.acronimo = '';
        state.nombre = '';
        state.email = '';
        state.departamentos = [];
        state.permisos = [];
        state.secciones = [];
        state.tipologiasbusqueda = null;
        state.tipologiasefirma = null;
        state.modoapp = '';
        state.autenticado = false;
      }
    },
  },
  getters: {
    username: (state) => state.username,
    compulsarennombrede: (state) => state.compulsarennombrede,
    nombre: (state) => state.nombre,
    email: (state) => state.email,
    acronimo: (state) => state.acronimo,
    token: (state) => state.token,
    autenticado: (state) => state.autenticado,
    tipologiasbusqueda: (state) => state.tipologiasbusqueda,
    tipologiasefirma: (state) => state.tipologiasefirma,
    departamentos: (state) => state.departamentos,
    permisos: (state) => state.permisos,
    secciones: (state) => state.secciones,
    modoapp: (state) => state.modoapp
  }
};
