<template>
  <div>
    <b-button variant="info" v-b-modal.modal-ayuda>Ayuda</b-button>

    <b-modal id="modal-ayuda" title="Ayuda" ok-only size="lg">
      <p>Esta herramienta facilita la tarea de enviar documentos a firmar tanto a personas de Manusa, como externos. Además, permite hacer un seguimiento de los documentos enviados.</p>
      <div>
        Antes de empezar, es importante tener en cuenta que:
        <ul>
          <li>Los documentos a enviar deben estar en <strong>formato PDF</strong></li>
          <li>Las opciones de envío están personalizadas dependiendo del perfil de cada usuario</li>
          <li>Una firma electrónica <strong>valida el documento completo</strong>, independientemente de donde ésta esté insertada. Ya no es necesario firmar cada página.</li>
          <li>Todos los firmantes recibirán copia del documento firmado una vez se complete por todas las partes.</li>
          <li>Cualquier duda relacionada con la aplicación o el concepto de firma electrónica, consultar con Angel Ortega (angel.ortega@manusa.com)</li>
        </ul>
      </div>

      <div v-for="s in vuexSeccionesdisponibles" :key="s.nombre" class="my-2">
        <div v-if="s.descripcion != ''">
          <h1><b-icon v-if="s.icono" :icon="s.icono" /> {{ s.texto }}</h1>
          <p v-html="s.descripcion"></p>
        </div>
      </div>

      Descárgate el manual completo <b-link href="/ayuda/EFIRMA-MANUAL-DEL-USUARIO.pdf" target="_blank">aquí</b-link>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AyudaGenerica',
  computed: {
    ...mapGetters({
      vuexAuthUsuario: 'user/autenticado',
      vuexNombreUsuario: 'user/nombre',
      vuexUsuSecciones: 'usuSecciones',
      vuexSeccionesdisponibles: 'seccionesdisponibles'
    })
  }
};
</script>

<style></style>
