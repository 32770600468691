<template>
  <div>
    <b-navbar fixed="top" class="pt-0 pb-0" toggleable="sm" id="navbar-cabecera-manusa">
      <b-navbar-brand href="/" style="min-width: 190px">
        <b-img src="/img/manusa-logo-negative.png" class="barra-logomanusa" alt="Manusa"></b-img>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto mr-auto d-none d-lg-block">
        <div style="font-size: 2em">
          {{ this.titulopagina }}
          <span v-show="vuexSeccion.length">- {{ vuexSeccion }}</span>
        </div>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto m-0 p-1 bg-danger" v-if="vuexModoApp == 'SIGNATURITDEV'">
        <div class="font-weight-bolder" style="font-size: 2em">DEV</div>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto m-0 p-0">
        <b-nav-item-dropdown right style="color: white" no-caret>
          <template v-slot:button-content v-if="vuexAuthUsuario">
            <b-avatar size="3em" :text="vuexAcronimoUsuario" variant="primary" class="m-0 p-0"></b-avatar>
          </template>
          <div v-if="vuexAuthUsuario">
            <div>
              <b-dropdown-text>{{ vuexNombreUsuario }}</b-dropdown-text>
              <ayuda class="text-center my-4"></ayuda>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item active>
                <b-link href="#" @click="logout">Desconectar</b-link>
              </b-dropdown-item>
            </div>
          </div>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>

    <b-container style="margin-top: 7em">
      <div class="d-lg-none" style="font-size: 1.2em; font-weight: bold">
        {{ this.titulopagina }}
        <span v-show="vuexSeccion.length">- {{ vuexSeccion }}</span>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Ayuda from './Ayuda.vue';

export default {
  components: { Ayuda },
  name: 'BarraManusa',
  props: {
    titulopagina: String
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
      console.log(this.$router);
    }
  },
  computed: {
    ...mapGetters({
      vuexSeccion: 'seccion',
      vuexUsernameUsuario: 'user/username',
      vuexAcronimoUsuario: 'user/acronimo',
      vuexEmailUsuario: 'user/email',
      vuexTokenUsuario: 'user/token',
      vuexNombreUsuario: 'user/nombre',
      vuexAuthUsuario: 'user/autenticado',
      vuexModoApp: 'user/modoapp'
    })
  }
};
</script>

<style scoped>
#navbar-cabecera-manusa {
  background-color: var(--color-manusa-azul) !important;
  color: #fff !important;
}
#navbar-cabecera-manusa h1,
#navbar-cabecera-manusa h2,
#navbar-cabecera-manusa h3,
#navbar-cabecera-manusa a:link,
#navbar-cabecera-manusa a:visited {
  color: #fff !important;
}
#navbar-cabecera-manusa a:link,
#navbar-cabecera-manusa a:hover {
  color: rgb(221, 221, 221) !important;
}
</style>
