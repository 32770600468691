<template>
  <div>        
    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="warning"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      <p>¡Se ha producido un error!</p>
      <div v-html="mensajeerror"></div>
      <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress>
    </b-alert>
  </div>
</template>

<script>
    export default {
        props: {
            mensajeerror: {
                type: String,
                default: ""
            },
        },
        mounted() {
            if (this.mensajeerror.length) this.dismissCountDown = this.dismissSecs*(1+Math.round(this.mensajeerror.length/100))
        },
        data() {
            return {
                dismissSecs: 10,
                dismissCountDown: 0,
                showDismissibleAlert: false
            }
        },
        methods: {
            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },
        },
        watch: {
            dismissCountDown(newValue, oldValue) {
                if (oldValue>newValue && newValue===0) this.$emit('cerrado')
            }
        },
    }
</script>