import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {BootstrapVueIcons} from 'bootstrap-vue'
Vue.use(BootstrapVueIcons)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: () => import('../views/InicioView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/efirma-estampayrubrica-multipagina',
    name: 'EFirmaEstampaYRubricaMultipaginaView',
    component: () => import('../views/EFirmaEstampaYRubricaMultipaginaView.vue')
  },
  {
    path: '/gestion-documentos-listado',
    name: 'ListadoDocsView',
    component: () => import('../views/ListadoDocsView.vue')
  },
  {
    path: '/efirma-firmanvarios',
    name: 'EFirmaFirmanVarios',
    component: () => import('../views/EFirmaFirmanVarios.vue')
  },
  {
    path: '/efirma-soloyo',
    name: 'EFirmaSoloYo',
    component: () => import('../views/EFirmaSoloYo.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/AdminView.vue')
  },
  { path: '*', component: () => import('../views/NotFoundView.vue') }
]

const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL
})

export default router
