<template>
  <div id="app">
    <BarraManusa :titulopagina="titulopagina"></BarraManusa>    
    <router-view/>
  </div>
</template>

<script>
import BarraManusa from '@/components/BarraManusa.vue'

export default {
  name: "App",
  components: {
    BarraManusa,
  },
  data() {
    return {
      titulopagina: "eFirma",
    }
  },
}
</script>
