import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
require('@/assets/styles/manusa.css');
require('@/assets/styles/efirma.css');

// si se indica token en la querystring, se permite acceder

const queryString = window.location.search;
if (queryString) {
  //se obtiene el token del queryString
  const TOKEN = queryString.split('token=')[1].split('&')[0];
  if (TOKEN != '') {
    store.dispatch('LoginYConfiguracion', TOKEN, null);
    sessionStorage.clear()
  }
}

// se carga la información de la sesión (si la hubiera)
if (sessionStorage.getItem("usuario")) {
  store.dispatch("user/setUser", JSON.parse(sessionStorage.getItem('usuario')))
  store.dispatch("user/setToken", sessionStorage.getItem('token'))
  store.dispatch("setCertificados", JSON.parse(sessionStorage.getItem('certificados')))
  store.dispatch("setEmpresas", JSON.parse(sessionStorage.getItem('empresas')))
  store.dispatch("setComitesdeempresa", JSON.parse(sessionStorage.getItem('comitesdeempresa')))
  store.dispatch("setConsejoadministracion", JSON.parse(sessionStorage.getItem('consejoadministracion')))
  store.dispatch("setTipologiasactivas", JSON.parse(sessionStorage.getItem('tipologiasactivas')))
  store.dispatch("setPosiciones", JSON.parse(sessionStorage.getItem('posiciones')))
  store.dispatch("setRubricas", JSON.parse(sessionStorage.getItem('rubricas')))
  store.dispatch("setTiposdocumentocompulsar", JSON.parse(sessionStorage.getItem('tiposdocumentocompulsar')))
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
