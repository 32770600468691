<template>
  <b-container class="contendordepagina">
    <!-- {{ vuexAuthUsuario }} - {{ vuexNombreUsuario }} -->
    <div v-if="vuexAuthUsuario && vuexNombreUsuario.length">
      
      
      <b-jumbotron header="" lead="Asistente para el envío de documentos a firmar mediante firma electrónica">
        <b-row>
          <b-col>
            <p>Bienvenido <strong>{{ vuexNombreUsuario }}</strong></p>
          </b-col>
        </b-row>
      </b-jumbotron>
      <div class="text-center">
        <b-row>
          <b-col cols="6" lg="3" v-for="s in seccionesvisibles" :key="s.nombre" class="centrar mb-5">
            <b-link class="enlaceseccion" :href="s.enlace">
              <b-icon v-if="s.icono" :icon="s.icono" style="width: 120px; height: 120px" /><br />
              {{ s.texto }}
            </b-link>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else>
      <b-jumbotron class="alert-danger">
        <span class="font-weight-bold">¡No estás autenticado!</span><br />
        Para poder hacer uso de esta herramienta, debes acceder a través del
        <a href="//portal.manusa.com/spa">Portal de empleado</a><br>
        <br>
        o, si desde Manusa-IT te han proporcionado los datos de acceso, indícalos a continuación:
        
        <b-row class="m-4">
          <b-col cols=12 md="6" lg="4">
            <b-form-group label-cols="4" label-class="font-weight-bold pt-0" label="Email">
              <b-form-input v-model="username"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="4" label-class="font-weight-bold pt-0" label="Token efirma">
              <b-form-input type="password" v-model="token"></b-form-input>
            </b-form-group>
            <b-button variant="success" @click="login">Aceptar</b-button>
          </b-col>
        </b-row>
        <AlertaError v-if="mensajeerror" :mensajeerror="mensajeerror"></AlertaError>
      </b-jumbotron>
    </div>

  </b-container>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import AlertaError from '@/components/AlertaError.vue'


export default {
  name: 'HomeView',
  components: {
    AlertaError
  },
  data() {
    return {
      seccionesvisibles: [],
      username: null,
      token: null,
      mensajeerror: null
    }
  },
  mounted() {
    this.inicializar();
  },
  methods: {
    login() {
      if (this.username && this.username.length>5 && this.token && this.token.length==36) {
        this.$store.dispatch('LoginYConfiguracion', this.token, this.username);
      } else {
        this.mensajeerror="Datos no válidos"
      }
    },
    inicializar() {
      if (this.vuexAuthUsuario) {
        const ususecciones = this.vuexUsuSecciones;        
        const rubricasactivasyvisibles = this.vuexRubricas.filter(r => r.activo && this.vuexUsuCompulsarennombrede.filter(str => str === r.codigo).length)
        console.log("rubricasactivasyvisibles", rubricasactivasyvisibles)
        console.log('ususecciones', ususecciones);
        this.seccionesvisibles = [];
        this.vuexSeccionesdisponibles.forEach((s) => {
          if (s.nombre == "estampayrubrica") {
            if (this.vuexUsuCompulsarennombrede.length && (this.vuexUsuCompulsarennombrede[0]=="*" || rubricasactivasyvisibles.length)) {
              this.seccionesvisibles.push(s);
            }
          } else {
            if (ususecciones[0] === '*') {
              this.seccionesvisibles.push(s);
            } else {
              ususecciones.forEach((t) => {
                if (s.grupo === t) this.seccionesvisibles.push(s);
              });
            }
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      vuexAuthUsuario: 'user/autenticado',
      vuexNombreUsuario: 'user/nombre',
      vuexUsuSecciones: 'usuSecciones',
      vuexSeccionesdisponibles: 'seccionesdisponibles',
      vuexUsuCompulsarennombrede: 'usuCompulsarennombrede',
      vuexUsuUsername: 'usuUsername',
      vuexRubricas: 'rubricas'
    })
  }
    
}
</script>
