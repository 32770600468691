import Vue from 'vue'
import Vuex from 'vuex'
import userStore from './user'
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_HOSTPYAPI

Vue.use(Vuex)
export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    seccion: 'INICIO',
    certificados: [],
    empresas: [],
    comitesdeempresa: [],
    consejoadministracion: [],
    tipologiasactivas: [],
    posiciones: [],
    rubricas: [],
    tiposdocumentocompulsar: [],

    seccionesdisponibles: [
      {
        grupo: 'MISEFIRMA',
        texto: 'Mis efirma',
        nombre: 'listadodocumentos',
        icono: 'table',
        enlace: '/gestion-documentos-listado',
        descripcion: 'Listado de todos los envíos realizados como remitente o como perteneciente a alguno de los distintos grupos de envío'
      },
      {
        grupo: 'SFY',
        texto: 'Solo firmo yo',
        nombre: 'soloyo',
        icono: 'person-circle',
        enlace: '/efirma-soloyo',
        descripcion:
          "Permite firmar un documento a través de Signaturit de forma inmediata siempre y cuando el firmante sea <strong>única y exclusivamente el propio usuario</strong>.<div class='alert alert-danger'>Se consume un crédito con cada documento enviado a firmar</div>"
      },
      {
        grupo: 'FFVV',
        texto: 'Firman varios',
        nombre: 'firmanvarios',
        icono: 'people-fill',
        enlace: '/efirma-firmanvarios',
        descripcion:
          "Este es el proceso de firma que se empleará cuando el documento se deba firmar a través de Signaturit y cumpla alguna de estas dos condiciones:<ul><li>lo tiene que firmar una persona que no es el propio usuario</li><li>debe ser firmado por más de una persona</li></ul><div class='alert alert-danger'>Se consume un crédito con cada documento enviado a firmar - Asegúrate que es el documento final que todos los firmantes han acordado rubricar antes de enviarlo"
      },
      {
        grupo: 'EYR',
        texto: 'Estampa y rúbrica',
        nombre: 'estampayrubrica',
        icono: 'file-medical',
        enlace: '/efirma-estampayrubrica-multipagina',
        descripcion:
          "Permite <strong>compulsar un documento</strong> utilizando un sello persona compuesto de rúbrica y estampa de la empresa que se indique, llevándose a cabo en las páginas, posición y orientación indicadas. El PDF devuelto no tiene la misma consideración legal que una firma electrónica a través de Signaturit.<br>Este medio será empleado en circustancias muy concretas (p.e. firma de contratos). El firmante del documento y quien ejecuta la acción (si no es la misma persona), recibirán una copia del documento compulsado por email.<div class='alert alert-info'>A diferencia de los firmados electrónicamente a través de Signaturit, estos documentos no quedan registrados.</div>"
      },
      {
        grupo: 'ADMIN',
        texto: 'Administración',
        nombre: 'administracion',
        icono: 'gear',
        enlace: '/admin',
        descripcion: ''
      }
    ]
  },
  actions: {
    async logout() {
      axios
        .post(
          '/logout',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.getters['usuToken']}`,
              'vi-100': process.env.VUE_APP_CLAVEAPIEFIRMA
            }
          }
        )
        .then((res) => {
          console.log(res)
          sessionStorage.removeItem('usuario')
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('certificados')
          sessionStorage.removeItem('empresas')
          sessionStorage.removeItem('comitesdeempresa')
          sessionStorage.removeItem('consejoadministracion')
          sessionStorage.removeItem('tipologiasactivas')
          sessionStorage.removeItem('posiciones')
          sessionStorage.removeItem('rubricas')
          sessionStorage.removeItem('tiposdocumentocompulsar')
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          // always executed
          document.location.href = '/inicio'
        })
    },
    async LoginYConfiguracion(NULL, token, email) {
      axios
        .post(
          '/login',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              'vi-100': process.env.VUE_APP_CLAVEAPIEFIRMA
            }
          }
        )
        .then((res) => {
          console.log('cargarDatosUsuarioSegunToken', res.data)
          if (!res.data.error) {
            if (!email || (email && res.data.user.email === email)) {
              // se registra en la sesión
              sessionStorage.setItem('usuario', JSON.stringify(res.data.usuario))
              sessionStorage.setItem('token', token)
              sessionStorage.setItem('certificados', JSON.stringify(res.data.certificados))
              sessionStorage.setItem('empresas', JSON.stringify(res.data.empresas))
              sessionStorage.setItem('comitesdeempresa', JSON.stringify(res.data.comitesdeempresa))
              sessionStorage.setItem('consejoadministracion', JSON.stringify(res.data.consejoadministracion))
              sessionStorage.setItem('tipologiasactivas', JSON.stringify(res.data.tipologiasactivas))
              sessionStorage.setItem('posiciones', JSON.stringify(res.data.posiciones))
              sessionStorage.setItem('rubricas', JSON.stringify(res.data.rubricas))
              sessionStorage.setItem('tiposdocumentocompulsar', JSON.stringify(res.data.tiposdocumentocompulsar))
            } else console.log('datos de acceso no válidos!')
          }
        })
        .catch(function (error) {
          sessionStorage.removeItem('usuario')
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('certificados')
          sessionStorage.removeItem('empresas')
          sessionStorage.removeItem('comitesdeempresa')
          sessionStorage.removeItem('consejoadministracion')
          sessionStorage.removeItem('tipologiasactivas')
          sessionStorage.removeItem('posiciones')
          sessionStorage.removeItem('rubricas')
          sessionStorage.removeItem('tiposdocumentocompulsar')
          console.log('error', error)
        })
        .then(function () {
          // always executed
          document.location.href = '/inicio'
        })
    },
    setSeccion({ commit }, s) {
      commit('SET_SECCION', s)
    },
    setCertificados({ commit }, c) {
      commit('SET_CERTIFICADOS', c)
    },
    setEmpresas({ commit }, e) {
      commit('SET_EMPRESAS', e)
    },
    setComitesdeempresa({ commit }, cde) {
      commit('SET_COMITESDEEMPRESA', cde)
    },
    setConsejoadministracion({ commit }, ca) {
      commit('SET_CONSEJOADMINISTRACION', ca)
    },
    setTipologiasactivas({ commit }, ta) {
      commit('SET_TIPOLOGIASACTIVAS', ta)
    },
    setPosiciones({ commit }, p) {
      commit('SET_POSICIONES', p)
    },
    setRubricas({ commit }, r) {
      commit('SET_RUBRICAS', r)
    },
    setTiposdocumentocompulsar({ commit }, r) {
      commit('SET_TIPOSDOCUMENTOCOMPULSAR', r)
    }
  },
  mutations: {
    SET_SECCION(state, s) {
      state.seccion = s
    },
    SET_CERTIFICADOS(state, c) {
      state.certificados = c
    },
    SET_EMPRESAS(state, e) {
      state.empresas = e
    },
    SET_COMITESDEEMPRESA(state, c) {
      state.comitesdeempresa = c
    },
    SET_CONSEJOADMINISTRACION(state, c) {
      state.consejoadministracion = c
    },
    SET_TIPOLOGIASACTIVAS(state, c) {
      state.tipologiasactivas = c
    },
    SET_POSICIONES(state, c) {
      state.posiciones = c
    },
    SET_RUBRICAS(state, c) {
      state.rubricas = c
    },
    SET_TIPOSDOCUMENTOCOMPULSAR(state, c) {
      state.tiposdocumentocompulsar = c
    }
  },
  modules: {
    user: userStore
  },
  getters: {
    seccionesdisponibles: (state) => state.seccionesdisponibles,
    empresas: (state) => state.empresas,
    comitesdeempresa: (state) => state.comitesdeempresa,
    seccion: (state) => state.seccion,
    certificados: (state) => state.certificados,
    consejoadministracion: (state) => state.consejoadministracion,
    tipologiasactivas: (state) => state.tipologiasactivas,
    posiciones: (state) => state.posiciones,
    rubricas: (state) => state.rubricas,
    tiposdocumentocompulsar: (state) => state.tiposdocumentocompulsar,
    usuTipologiasbusqueda: (state) => state.user.tipologiasbusqueda,
    usuTipologiasefirma: (state) => state.user.tipologiasefirma, // son aquellas con acceso == 'READWRITE'
    usuEmail: (state) => state.user.email,
    usuDepartamentos: (state) => state.user.departamentos,
    usuSecciones: (state) => state.user.secciones,
    usuPermisos: (state) => state.user.permisos,
    usuModoApp: (state) => state.user.modoapp,
    usuToken: (state) => state.user.token,
    usuUsername: (state) => state.user.username,
    usuCompulsarennombrede: (state) => state.user.compulsarennombrede
  }
})
